import React, {useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    Checkbox,
    filterConstants,
    datesConstants,
    CustomDropdown,
    ErrorMessageAction,
    DeleteLessonAction,
    AddClassCodesToSelectionAction,
    ReloadListAction, SuccessMessageAction
} from "mindsets-js-sdk";

import GradeLevels from "./GradeLevels";
import EditMenuItem from "./EditMenuItem";
import DeleteMenuItem from "./DeleteMenuItem";
import StreamsMenuItem from "./StreamsMenuItem";
import PauseMenuItem from "./PauseMenuItem";
import UnpauseMenuItem from "./UnpauseMenuItem";
import {ScheduleLessonResponsesForGradingAction} from "../../../actions/lesson_actions";

function Row({item, row_number}) {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.lessons_curricula);
    const practices = useSelector(state => state.lessons_practices);
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const [code, setCode] = useState('');
    const curriculum = curricula.find(curriculum => curriculum.id === item.user.curriculum_id);
    const getAssessmentStatus = function () {
        if (item.challenge_version.data_version === 0) {
            return 'N/A'
        }
        if (!item.lesson_scoring_schedules || item.lesson_scoring_schedules.length <= 0) {
            return 'N/R'
        }

        return practices.map(practice => {
            const schedule = item.lesson_scoring_schedules.find(schedule => schedule.practice_id === practice.id);

            let status = <span className="badge bg-secondary text-light">Not requested</span>;
            if (schedule && schedule.status === 'finished') {
                status = <span className="badge bg-info text-light">Finished</span>;
            } else if (schedule && schedule.status === 'started') {
                status = <span className="badge bg-warning">Started</span>;
            } else if (schedule && schedule.status === 'requested') {
                status = <span className="badge bg-primary text-light">Requested</span>;
            } else if (schedule && schedule.status === 'QA passed') {
                status = <span className="badge bg-success text-light">QA passed</span>;
            }

            let completion = <span className="badge bg-secondary text-light">All</span>;
            if (schedule && schedule.completion === 'completed_bonus') {
                completion = <span className="badge bg-success text-light">Completed bonus</span>;
            } else if (schedule && schedule.completion === 'completed') {
                completion = <span className="badge bg-success text-light">Completed</span>;
            } else if (schedule && schedule.completion === 'nearly') {
                completion = <span className="badge bg-primary text-light">Nearly completed</span>;
            } else if (schedule && schedule.completion === 'underway') {
                completion = <span className="badge bg-warning text-light">Underway</span>;
            }

            return (
                <div key={practice.id + '_practice'} className='no-wrap'>{practice.code}: {status} | {completion}</div>
            );
        });
    }

    function requestAutoGrading(id) {
        dispatch(ScheduleLessonResponsesForGradingAction(id)).then(() => {
            dispatch(SuccessMessageAction('Responses have been scheduled for auto-grading'));
        });
    }

    function renderFilters() {
        if (item.filters && item.filters.constructor.name === 'Object') {
            return Object.keys(item.filters).map(key => {
                return (
                    <div key={key}>{key}: {item.filters[key]}</div>
                )
            });
        }

        return null;
    }

    return (
        <tr>
            <td>
                <Checkbox
                    checked={selected_lesson_codes.includes(item.code)}
                    toggle={() => {
                        let array = JSON.parse(JSON.stringify(selected_lesson_codes));
                        if (array.includes(item.code)) {
                            array.splice(array.findIndex(lesson_code => lesson_code === item.code), 1);
                        } else {
                            array.push(item.code);
                        }
                        dispatch(AddClassCodesToSelectionAction(array));
                    }}
                />
            </td>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>
                <div><strong>{item.code.toUpperCase()}</strong></div>
                <div>{item.name}</div>
                <div>{item.challenge_version.name}</div>
            </td>
            <td>
                <Link
                    to={'/users/' + item.user.id}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit teacher"
                >{item.user.fname + ' ' + item.user.lname}</Link>
                <div>({curriculum ? curriculum.name : 'Curriculum not selected'})</div>
                <div>({item.user.school ? item.user.school.name : 'School not selected'})</div>
            </td>
            <td className="text-center">
                {item.students_count > 0 ?
                    <Link
                        to={`/users?column~0=student_lesson_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${item.id}`}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Show all the students in the Class"
                    >{item.students_count}</Link> : item.students_count}
                {item.provider ? <div>Students from {item.provider}</div> : null}
            </td>
            <td className="text-center">{item.co_teachers_count > 0 ?
                <Link
                    to={`/users?column~0=lesson_co_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${item.id}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Show all the co-teachers in the Class"
                >{item.co_teachers_count}</Link> : item.co_teachers_count}</td>
            <td>{item.status}</td>
            <td>{item.mode}</td>
            <td className='no-wrap'>{item.last_activity_date ? new Date(item.last_activity_date * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT) : 'N/A'}</td>
            <td>{getAssessmentStatus()}</td>
            <td><GradeLevels grade_levels={item.grade_level}/></td>
            <td>{renderFilters()}</td>
            <td className='no-wrap'>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='text-center'>
                <span
                    className={item.number_responses_graded < item.number_responses_to_grade ? 'text-danger' : ''}
                >{item.number_responses_graded >= 0 ? item.number_responses_graded : 'na'}</span>
                <span>/</span>
                <span
                    className='text-info'>{item.number_responses_to_grade >= 0 ? item.number_responses_to_grade : 'na'}</span>
                <span>/</span>
                <span className='text-success'>{item.number_responses >= 0 ? item.number_responses : 'na'}</span>
            </td>
            <td>
                <CustomDropdown
                    trigger={<i className="fas fa-ellipsis-v fa-lg"/>}
                    no_wrap={true}
                    content_class='dropdown-to-left'
                    items={{
                        streams: <StreamsMenuItem id={item.id}/>,
                        edit: <EditMenuItem id={item.id}/>,
                        pause: <PauseMenuItem id={item.id} status={item.status}/>,
                        unpause: <UnpauseMenuItem id={item.id} status={item.status}/>,
                        grade: <button id={item.id} className='button mx-2 my-1'
                                       onClick={() => requestAutoGrading(item.id)}>Request auto-grading</button>,
                        delete: <DeleteMenuItem id={item.id}/>
                    }}
                    use_arrow={false}
                />
                <div className="modal fade" id={'delete-lesson-modal-' + item.id} tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">You are about to
                                    delete {item.name} Code</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this Class?</p>
                                <p>All the data including student progress will be lost</p>
                                <p>To confirm, please enter Code into the field below.</p>
                                <div className='input-field'>
                                    <input type='text' onChange={event => setCode(event.target.value)} value={code}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="button" onClick={event => {
                                    event.preventDefault();
                                    if (code.toLowerCase() === item.code.toLowerCase()) {
                                        dispatch(DeleteLessonAction(item.id)).then(() => dispatch(ReloadListAction(true, 'lessons')));
                                        bootstrap.Modal.getInstance(document.getElementById('delete-lesson-modal-' + item.id)).hide();
                                    } else {
                                        dispatch(ErrorMessageAction('Please enter correct Class Code'));
                                    }
                                }}>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        mode: PropTypes.string.isRequired,
        students_count: PropTypes.number,
        co_teachers_count: PropTypes.number,
        created_at: PropTypes.number.isRequired,
        provider: PropTypes.string,
        grade_level: PropTypes.array,
        filters: PropTypes.object,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            fname: PropTypes.string,
            lname: PropTypes.string,
            school: PropTypes.shape({
                name: PropTypes.string.isRequired,
            })
        }).isRequired,
        challenge_version: PropTypes.shape({
            name: PropTypes.string.isRequired,
            data_version: PropTypes.number.isRequired,
            challenge_code: PropTypes.shape({
                subject: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        lesson_scoring_schedules: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string.isRequired,
            user_id: PropTypes.number,
            lesson_id: PropTypes.number,
            practice_id: PropTypes.number.isRequired,
            completion: PropTypes.string,
            created_at: PropTypes.number
        })),
        last_activity_date: PropTypes.number,
        number_responses: PropTypes.number.isRequired,
        number_responses_to_grade: PropTypes.number.isRequired,
        number_responses_graded: PropTypes.number.isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
