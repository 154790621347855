import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction, NumericValueField} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();

    const [model, setModel] = useState('us.meta.llama3-2-90b-instruct-v1:0');
    const [max_gen_len, setMaxGenLen] = useState(1024);
    const [temperature, setTemperature] = useState(0.0);
    const [system_prompt, setSystemPrompt] = useState('');
    const [user_prompt, setUserPrompt] = useState('');
    const [request, setRequest] = useState('');
    const [reply, setReply] = useState({});

    function renderReply() {
        let input_tokens = 'n/a';
        if (reply && reply.usage && reply.usage.input_tokens) {
            input_tokens = reply.usage.input_tokens;
        }
        if (reply && reply.prompt_token_count) {
            input_tokens = reply.prompt_token_count;
        }
        let output_tokens = 'n/a';
        if (reply && reply.usage && reply.usage.output_tokens) {
            output_tokens = reply.usage.output_tokens;
        }
        if (reply && reply.generation_token_count) {
            output_tokens = reply.generation_token_count;
        }
        let output = [];
        if (reply && Array.isArray(reply.content)) {
            output = reply.content.filter(item => item.type === 'text').map(item => item.text);
        }
        if (reply && Array.isArray(reply.outputs)) {
            output = reply.outputs.map(item => item.text);
        }
        if (reply && reply.generation) {
            output.push(reply.generation);
        }
        return (
            <div className='my-2'>
                <div>Input tokens: {input_tokens}</div>
                <div>Output tokens: {output_tokens}</div>
                <div className='my-4'>
                    {output.map((item, i) => {
                        return (
                            <div key={i} dangerouslySetInnerHTML={{__html: item.replaceAll("\n", "<br/>")}}/>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className='container'>
            <h1>Ask AI</h1>

            <div className='my-2 d-flex flex-gap-10'>
                <div>
                    <label>Model</label>
                    <select value={model} onChange={event => setModel(event.target.value)}>
                        <option value='us.meta.llama3-3-70b-instruct-v1:0'>Llama 3.3 70B</option>
                        <option value='us.meta.llama3-2-90b-instruct-v1:0'>Llama 3.2 90B</option>
                        <option value='meta.llama3-70b-instruct-v1:0'>Llama 3 70B</option>
                        <option value='mistral.mistral-7b-instruct-v0:2'>Mistral 7B</option>
                        <option value='us.anthropic.claude-3-5-haiku-20241022-v1:0'>Claude 3.5 Haiku</option>
                    </select>
                </div>

                <div>
                    <label>Temperature (0.0 - 1.0)</label>
                    <NumericValueField callback={setTemperature} initial={temperature}/>
                </div>

                <div>
                    <label>Max generation length</label>
                    <NumericValueField callback={setMaxGenLen} initial={max_gen_len}/>
                </div>
            </div>

            <label className='my-2'>NOTE: The temperature parameter in Large Language Models (LLMs) directly affects the
                variability and randomness of generated responses. A lower LLM temperature value (close to 0) produces
                more deterministic and focused outputs, ideal for tasks requiring factual accuracy, such as
                summarization or translation. Conversely, a higher temperature value (e.g., 1.0 or above) introduces
                more diversity and creativity, as the model samples from a broader range of possible answers.</label>

            <div className='mt-3'>
                <label>Context and requirements(optional)</label>
                <textarea
                    value={system_prompt}
                    onChange={event => setSystemPrompt(event.target.value)}
                    style={{width: '100%'}}
                    rows={8}
                    placeholder='Describe the scene'
                />
            </div>

            <div className='my-2'>
                <label>Attach data(optional)</label>
                <textarea
                    value={user_prompt}
                    onChange={event => setUserPrompt(event.target.value)}
                    style={{width: '100%'}}
                    rows={8}
                />
            </div>

            <div>
                <label>Your request *</label>
                <textarea
                    value={request}
                    onChange={event => setRequest(event.target.value)}
                    style={{width: '100%'}}
                    rows={4}
                />
            </div>

            <div className='my-2'>
                <button onClick={event => {
                    event.preventDefault();
                    dispatch(LoadingAction(true, 'Loading...'));
                    MindsetsJsSDK().API
                        .post('ai/ask', {model, max_gen_len, temperature, system_prompt, user_prompt, request})
                        .then(response => setReply(response.reply))
                        .catch(error => {
                            console.error(error);
                            dispatch(ErrorMessageAction(error, true));
                        })
                        .finally(() => dispatch(LoadingAction(false)));
                }}>Send
                </button>
            </div>

            {renderReply()}
        </div>
    );
}
