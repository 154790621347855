import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {
    accessConstants,
    authUtils,
    ErrorMessageAction,
    LoadingAction,
    UserDropdown,
    LoadChallengeVersionsAction,
    CustomDropdown,
    ChallengeDropdownItem
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import './lessons_create.scss';

export default function () {
    const me = useSelector(state => state.me);
    const challenge_versions = useSelector(state => state.challenge_versions);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([initRow()]);

    useEffect(() => {
        dispatch(LoadChallengeVersionsAction({order_by: 'name', limit: 0}));
    }, []);

    function initRow() {
        return {
            user: null,
            challenge: null,
            number: 1,
            lessons: []
        }
    }

    function renderChallengeItems(index) {
        const challenge_items = {};
        challenge_versions.map(challenge_version => {
            challenge_items[challenge_version.name + '-' + challenge_version.version] = (
                <div onClick={() => {
                    const rowsClone = JSON.parse(JSON.stringify(rows));
                    rowsClone[index].challenge = challenge_version;
                    setRows(rowsClone);
                }}>
                    <ChallengeDropdownItem item={challenge_version} with_version={true}/>
                </div>
            )
        });

        return challenge_items;
    }

    function renderChallengeTrigger(index) {
        let display = 'Select a Challenge';
        if (rows[index].challenge) {
            display = rows[index].challenge.name;
        }

        return (
            <div>
                <label>Challenge</label>
                <div className="d-flex align-items-center like-selector">{display}</div>
            </div>
        );
    }

    function renderClasses(index) {
        if (rows[index].lessons.length > 0) {
            return rows[index].lessons.map(lesson => {
                return (
                    <div key={lesson.code}>{lesson.code} {lesson.name}</div>
                );
            });
        }

        return null;
    }

    function renderSubmitButton() {
        let is_valid = true;
        rows.map(row => {
            if (!row.challenge || !row.user) {
                is_valid = false;
            }
        });

        return (
            <button
                onClick={event => {
                    event.preventDefault();
                    const promises = [];
                    const now = new Date();
                    const date = now.toLocaleDateString('en-US', {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric'
                    });
                    rows.map((row, index) => {
                        for (let i = 0; i < row.number; i++) {
                            const promise = new Promise((resolve, reject) => {
                                return Promise.resolve()
                                    .then(() => dispatch(LoadingAction(true, 'Creating Class #' + (i + 1) + ' for ' + row.user.fname + ' ' + row.user.lname)))
                                    .then(() => MindsetsJsSDK().API.post('lessons', {
                                        name: date + ' Class',
                                        challenge_version_id: row.challenge.id,
                                        user_id: row.user.id
                                    }))
                                    .then(response => {
                                        resolve(({
                                            index,
                                            lesson: response.lesson
                                        }))
                                    })
                                    .catch(error => {
                                        reject(error)
                                    });
                            });
                            promises.push(promise);
                        }
                    });
                    Promise.all(promises).then(lessons => {
                        const rowsClone = JSON.parse(JSON.stringify(rows));
                        lessons.map(lesson => {
                            rowsClone[lesson.index].lessons.push(lesson.lesson);
                        });
                        setRows(rowsClone);
                        dispatch(LoadingAction(false));
                    }).catch(error => {
                        console.error(error);
                        dispatch(ErrorMessageAction(error, true));
                        dispatch(LoadingAction(false));
                    });
                }}
                disabled={!is_valid}
            >Create
            </button>
        );
    }

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container section' id='lessons-create'>
            <h1>Create Classes</h1>

            <div>
                <button onClick={event => {
                    event.preventDefault();
                    const rowsClone = JSON.parse(JSON.stringify(rows));
                    rowsClone.push(initRow());
                    setRows(rowsClone);
                }}>+
                </button>
            </div>

            <div className='d-flex flex-column flex-gap-5'>
                {rows.map((row, index) => {
                    return (
                        <div key={index}
                             className='d-flex bordered flex-gap-5 justify-content-between align-items-center'>
                            <div className='d-flex flex-gap-5'>
                                <UserDropdown
                                    onSelect={user => {
                                        const rowsClone = JSON.parse(JSON.stringify(rows));
                                        rowsClone[index].user = user ? user : null;
                                        setRows(rowsClone);

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    label='Select Teacher'
                                    with_school={true}
                                    show_counts={true}
                                    debounce={debounce}
                                    id={'lessons-create-teacher_' + index}
                                    persona='teacher'
                                />

                                <CustomDropdown
                                    trigger={renderChallengeTrigger(index)}
                                    items={renderChallengeItems(index)}
                                    use_arrow={false}
                                    open_on_mouse_over={false}
                                    close_on_mouse_leave={true}
                                />

                                <div>
                                    <label>No. of Classes</label>
                                    <select className='like-selector' onChange={event => {
                                        const rowsClone = JSON.parse(JSON.stringify(rows));
                                        rowsClone[index].number = parseInt(event.target.value);
                                        setRows(rowsClone);
                                    }} value={rows[index].number}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                    </select>
                                </div>

                                <div>{renderClasses(index)}</div>
                            </div>

                            <div>
                                <button
                                    className='text-danger'
                                    onClick={event => {
                                        event.preventDefault();
                                        const rowsClone = JSON.parse(JSON.stringify(rows));
                                        rowsClone.splice(index, 1);
                                        setRows(rowsClone);
                                    }}>
                                    <i className='fas fa-trash'/>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div>
                {renderSubmitButton()}
            </div>
        </div>
    );
}
