import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {
    DatePicker,
    LoadedAutocompleteItemsAction,
    LoadedAutocompleteTotalsAction
} from "mindsets-js-sdk";
import Autocomplete from "mindsets-js-sdk/src/react/components/Autocomplete";

const Filters = function (
    {
        action,
        question_code,
        setQuestionCode,
        practice_id,
        setPracticeId,
        challenge_code,
        setChallengeCode,
        lesson_code,
        setLessonCode,
        grading_provider_id,
        setGradingProviderId,
        from, setFrom,
        till, setTill
    }
) {
    const dispatch = useDispatch();
    const questions = useSelector(state => state.questions);
    const practices = useSelector(state => state.responses_practices);
    const grading_providers = useSelector(state => state.grading_providers);
    const debounce = useCallback(_.debounce(a => a(), 600), []);

    function renderChallengeDropdown() {
        function getChallengeCodes(q) {
            let options = {q, 'selectors[0]': 'latest_challenge_version_name'};

            return MindsetsJsSDK().API.get('challenge_codes', options).then(response => {
                dispatch(LoadedAutocompleteItemsAction('grader-review-challenge', response.items));
                dispatch(LoadedAutocompleteTotalsAction('grader-review-challenge', response.items.length));
            });
        }

        return (
            <Autocomplete
                id={'grader-review-challenge'}
                fetchItems={debounce(getChallengeCodes, 600)}
                onSelect={challenge_code => {
                    if (challenge_code) {
                        setChallengeCode(challenge_code);
                    } else {
                        setChallengeCode(null);
                    }

                    return challenge_code ? challenge_code.latest_challenge_version_name : '';
                }}
                renderItem={item => {
                    return (
                        <div>
                            <div
                                className="autocomplete-dropdown-menu-item-title">{item.latest_challenge_version_name}</div>
                        </div>
                    )
                }}
                persist_value={true}
                show_counts={true}
            />
        )
    }

    return (
        <div className='section'>
            <div className='d-flex flex-column mb-2'>
                <label>Challenge</label>
                {renderChallengeDropdown()}
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Question</label>
                <select
                    onChange={event => setQuestionCode(event.target.value)}
                    value={question_code}
                >
                    <option value=''>All questions</option>
                    {questions.map(question => {
                        return (
                            <option
                                key={question.id}
                                value={question.code}
                            >Q{question.number} {question.title}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Practice</label>
                <select
                    value={practice_id}
                    onChange={event => setPracticeId(parseInt(event.target.value))}
                >
                    <option value=''>All Practices</option>
                    {practices.map(practice => {
                        return (
                            <option key={practice.id} value={practice.id}>{practice.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Grading Providers</label>
                <select
                    onChange={event => setGradingProviderId(parseInt(event.target.value))}
                    value={grading_provider_id}
                >
                    <option value=''>All graders</option>
                    {grading_providers.map(grading_provider => {
                        return (
                            <option
                                key={grading_provider.id}
                                value={grading_provider.id}
                            >{grading_provider.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Class codes(comma separated)</label>
                <input value={lesson_code} onChange={event => setLessonCode(event.target.value)}/>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Date range(time of grading)</label>
                <div className="d-flex flex-gap-10">
                    <DatePicker
                        range={true}
                        date={function () {
                            if (from && till) {
                                return [from, till];
                            }

                            return null;
                        }()}
                        onChange={timestamps => {
                            if (timestamps.from && timestamps.till) {
                                setFrom(timestamps.from);
                                setTill(timestamps.till);
                            }
                        }}
                        with_reset={true}
                    />
                </div>
            </div>
            <button
                className='large'
                onClick={event => {
                    event.preventDefault();
                    action();
                }}
                disabled={!challenge_code.code}
            >GET
            </button>
        </div>
    );
}

Filters.propTypes = {
    action: PropTypes.func.isRequired,
    question_code: PropTypes.string.isRequired,
    setQuestionCode: PropTypes.func.isRequired,
    challenge_code: PropTypes.object,
    setChallengeCode: PropTypes.func.isRequired,
    lesson_code: PropTypes.string,
    setLessonCode: PropTypes.func.isRequired,
    practice_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setPracticeId: PropTypes.func.isRequired,
    from: PropTypes.number,
    till: PropTypes.number,
    setFrom: PropTypes.func.isRequired,
    setTill: PropTypes.func.isRequired
}

export default Filters;
