import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {
    accessConstants,
    authUtils,
    ReloadListAction,
    UserDropdown,
    ErrorMessageAction,
    AddClassCodesToSelectionAction,
    LoadingAction, SuccessMessageAction
} from "mindsets-js-sdk";

import {ChangeOwnerAction} from "../../../actions/lesson_actions";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const class_codes = useSelector(state => state.selected_lesson_codes);
    const [user, setUser] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    function renderTransferOwnership() {
        return (
            <>
                <div
                    className="modal fade"
                    id={'transfer-lessons-ownership-modal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="transfer-lesson-ownership" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="transfer-lessons-ownership">Changing the owner of the
                                    Class</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setUser(user);
                                        } else {
                                            setUser(null);
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    label='Select Teacher'
                                    with_school={true}
                                    show_counts={true}
                                    debounce={debounce}
                                    id='lessons-bulk-users'
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="button" onClick={event => {
                                    event.preventDefault();
                                    if (!!user) {
                                        dispatch(ChangeOwnerAction({
                                            class_codes,
                                            user_id: user.id
                                        }))
                                            .then(() => bootstrap.Modal.getInstance(document.getElementById("transfer-lessons-ownership-modal")).hide())
                                            .then(() => dispatch(AddClassCodesToSelectionAction([])))
                                            .then(() => dispatch(ReloadListAction(true, 'lessons')))
                                            .then(() => dispatch(SuccessMessageAction('Classes\' owner has been changed.')));
                                    } else {
                                        dispatch(ErrorMessageAction('Please select a teacher'));
                                    }
                                }}>Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className='button red'
                    data-bs-toggle="modal"
                    data-bs-target={'#transfer-lessons-ownership-modal'}
                >
                    Change Owner
                </button>
            </>
        );
    }

    function renderDelete() {
        return (
            <button className='button red' onClick={event => {
                event.preventDefault();
                if (confirm('Are you sure you want to delete these lessons? Can not be undone.')) {
                    const promises = [];
                    class_codes.map(class_code => {
                        const promise = new Promise((resolve, reject) => {
                            return Promise.resolve()
                                .then(() => dispatch(LoadingAction(true, 'Creating Class ' + class_code)))
                                .then(() => MindsetsJsSDK().API.delete('lessons/' + class_code))
                                .then(() => resolve(true))
                                .catch(error => {
                                    reject(error)
                                });
                        });
                        promises.push(promise);
                    });
                    Promise.all(promises)
                        .then(() => dispatch(AddClassCodesToSelectionAction([])))
                        .then(() => dispatch(ReloadListAction(true, 'lessons')))
                        .then(() => {
                            dispatch(LoadingAction(false));
                            dispatch(SuccessMessageAction('Classes\' have been deleted successfully.'));
                        })
                        .catch(error => {
                            console.error(error);
                            dispatch(ErrorMessageAction(error, true));
                            dispatch(LoadingAction(false));
                        });
                }
            }}>
                Delete selected lessons
            </button>
        );
    }

    function renderRecalculate() {
        return (
            <button onClick={event => {
                event.preventDefault();
                const promises = [];
                class_codes.map(class_code => {
                    const promise = new Promise((resolve, reject) => {
                        return Promise.resolve()
                            .then(() => dispatch(LoadingAction(true, 'Recalculation Class ' + class_code + ' data')))
                            .then(() => MindsetsJsSDK().API.patch('lessons/' + class_code + '/recalculate'))
                            .then(() => resolve(true))
                            .catch(error => {
                                reject(error)
                            });
                    });
                    promises.push(promise);
                });
                Promise.all(promises)
                    .then(() => dispatch(AddClassCodesToSelectionAction([])))
                    .then(() => dispatch(ReloadListAction(true, 'lessons')))
                    .then(() => {
                        dispatch(LoadingAction(false));
                        dispatch(SuccessMessageAction('Classes\' have been recalculated successfully.'));
                    })
                    .catch(error => {
                        console.error(error);
                        dispatch(ErrorMessageAction(error, true));
                        dispatch(LoadingAction(false));
                    });
            }}>Recalculate</button>
        );
    }

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS]) && class_codes.length) {
        return (
            <>
                {renderRecalculate()}
                {renderTransferOwnership()}
                {renderDelete()}
            </>
        );
    }

    return null;
}
