import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import MindsetsJsSDK, {
    filterConstants,
    accessConstants,
    ListPage,
    ChallengeDropdown,
    DatePicker,
    LoadSchedulesAction,
    Autocomplete,
    DistrictDropdown,
    catchQueryFilters,
    LoadProjectBatchesAction,
    LoadedAutocompleteItemsAction,
    LoadedAutocompleteTotalsAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import Table from './Table';
import {
    LoadPracticesAction,
    LoadGradingProvidersAction
} from "../../actions/lesson_actions";

export const MINIMUM_COMPLETION_LEVELS = {
    stuck: 'Stuck',
    underway: 'Underway',
    nearly: 'Nearly Completed',
    completed: 'Completed',
    completed_bonus: 'Completed Bonus'
}

export default function () {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.lessons_practices);
    const grading_providers = useSelector(state => state.schedules_grading_providers);
    const batches = useSelector(state => state.project_batches);
    let [project_id, setProjectId] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        if (!practices.length) {
            dispatch(LoadPracticesAction());
        }
        if (!grading_providers.length) {
            dispatch(LoadGradingProvidersAction());
        }
        const filters_filtered = catchQueryFilters().filter(filter => filter.column === 'project_id')[0];
        if (filters_filtered) {
            setProjectId(parseInt(filters_filtered.value));
        }
    }, []);
    useEffect(() => {
        if (project_id) {
            dispatch(LoadProjectBatchesAction({
                'filters[project_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + project_id,
                order_by: 'name',
                order_direction: 'asc'
            }));
        }
    }, [project_id]);

    return (
        <>
            <h1>Class schedule for grading</h1>
            <ListPage
                action={options => LoadSchedulesAction(Object.assign({
                    'selectors[0]': 'lesson_challenge_version',
                    'selectors[1]': 'number_students',
                    'selectors[2]': 'number_students_to_grade',
                    'selectors[3]': 'number_students_graded',
                    'selectors[4]': 'number_responses',
                    'selectors[5]': 'number_responses_to_grade',
                    'selectors[6]': 'number_responses_graded',
                    'selectors[7]': 'number_responses_gradable',
                    'selectors[8]': 'number_responses_auto_graded'
                }, options))}
                filter_columns={[
                    {
                        key: 'practice_id',
                        name: 'Practice',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: practices.map(practice => ({
                            label: practice.name,
                            value: practice.id
                        })),
                        multiple: false
                    },
                    {
                        key: 'lesson_code',
                        name: 'Class Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'challenge_code',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown
                                    onSelect={challenge_version => {
                                        if (challenge_version) {
                                            setFilterValue(challenge_version.challenge_code.code);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return challenge_version ? challenge_version.name : '';
                                    }}
                                    id='lesson-schedules-challenge-filter'
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'created_at',
                        name: 'Scheduled on',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];

                            return (
                                <DatePicker
                                    date={filter && filter.value ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'lesson_created_at',
                        name: 'Class created on',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];

                            return (
                                <DatePicker
                                    date={filter && filter.value ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'grading_provider_id',
                        name: 'Grading Provider',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: grading_providers.map(grading_provider => ({
                            label: grading_provider.name,
                            value: grading_provider.id
                        })),
                        multiple: false
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Requested',
                                value: 'requested'
                            },
                            {
                                label: 'Started',
                                value: 'started'
                            },
                            {
                                label: 'Finished',
                                value: 'finished'
                            },
                            {
                                label: 'QA Passed',
                                value: 'QA passed'
                            }
                        ]
                    },
                    {
                        key: 'project_id',
                        name: 'Project',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <Autocomplete
                                    id='schedules-projects-filter'
                                    placeholder='Project name'
                                    fetchItems={name => {
                                        debounce(function () {
                                            MindsetsJsSDK().API.get('projects', {
                                                'filters[name][0]': filterConstants.EXPRESSION_CONTAINS + '=' + name
                                            })
                                                .then(response => {
                                                    dispatch(LoadedAutocompleteItemsAction('schedules-projects-filter', response.items));
                                                    dispatch(LoadedAutocompleteTotalsAction('schedules-projects-filter', response.items.length));
                                                })
                                                .catch(error => {
                                                    console.error(error);
                                                    dispatch(ErrorMessageAction('Failed loading projects'));
                                                });
                                        });
                                    }}
                                    onSelect={project => {
                                        if (project) {
                                            setFilterValue(project.id);
                                            setProjectId(project.id);
                                        } else {
                                            setFilterValue('');
                                            setProjectId(null);
                                        }

                                        return project ? project.name : '';
                                    }}
                                    renderItem={project => (
                                        <div className="autocomplete-dropdown-menu-item-title">{project.name}</div>)}
                                    persist_value={true}
                                    show_counts={true}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'project_batch_id',
                        name: 'Project Batch',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const project_filters_filtered = filters.filter(filter => filter.column === 'project_id');
                            const batch_filter = filters.filter(filter => filter.column === 'project_batch_id')[0];
                            if (project_filters_filtered.length === 1) {
                                return (
                                    <select
                                        value={batch_filter ? batch_filter.value : ''}
                                        onChange={event => {
                                            const value = event.target.value;
                                            if (!isNaN(parseInt(value))) {
                                                setFilterValue(parseInt(value));
                                            } else {
                                                setFilterValue('');
                                            }
                                        }}
                                    >
                                        <option value=''>Select a Batch</option>
                                        {batches.map(batch => {
                                            return (
                                                <option key={batch.id} value={batch.id}>{batch.name}</option>
                                            );
                                        })}
                                    </select>
                                );
                            } else {
                                return (
                                    <Autocomplete
                                        id='schedules-project-batches-filter'
                                        placeholder='Project Batch'
                                        fetchItems={q => {
                                            debounce(function () {
                                                MindsetsJsSDK().API.get('project_batches', {
                                                    q,
                                                    order_by: 'name',
                                                    order_direction: 'asc'
                                                })
                                                    .then(response => {
                                                        dispatch(LoadedAutocompleteItemsAction('schedules-project-batches-filter', response.items));
                                                        dispatch(LoadedAutocompleteTotalsAction('schedules-project-batches-filter', response.items.length));
                                                    })
                                                    .catch(error => {
                                                        console.error(error);
                                                        dispatch(ErrorMessageAction('Failed loading projects'));
                                                    });
                                            });
                                        }}
                                        onSelect={batch => {
                                            if (batch) {
                                                setFilterValue(batch.id);
                                            } else {
                                                setFilterValue('');
                                            }

                                            return batch ? batch.name : '';
                                        }}
                                        renderItem={batch => (
                                            <div className="autocomplete-dropdown-menu-item-title">{batch.name}</div>)}
                                        persist_value={true}
                                        show_counts={true}
                                    />
                                );
                            }
                        },
                        multiple: false
                    },
                    {
                        key: 'district_id',
                        name: 'By School District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown
                                    onSelect={district => {
                                        if (district) {
                                            setFilterValue(district.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return district ? district.name : '';
                                    }}
                                    id='schedules-district-filter'
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'created_at',
                        name: 'Created At'
                    },
                    {
                        key: 'lesson_code',
                        name: 'Class Code'
                    }
                ]}
                entity={'schedules'}
                create_button={<Link to='/lesson_schedules/new' className='button'>Schedule</Link>}
                permissions={[accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS]}
                default_order_direction='desc'
            >
                <Table/>
            </ListPage>
        </>
    );
}
